import BasicStructure from '../components/basic-structure/basic-structure';
import React from 'react';
import TitleMetaTags from '../components/title-meta-tags/title-meta-tags';
import ContentContainer from '../components/content-container/content-container';
import { graphql } from 'gatsby';
import { getWpBlock } from '../components/wp-blocks';
import Footer from '../components/footer/footer';
import './aktuell-speziell.css';
import Header from '../components/header/header';
import Menubar from '../components/menu/menubar';
import BurgerMenu from '../components/burgermenu/burgermenu';
import Sidebar from '../components/sidebar/sidebar';
import ScrollUp from '../components/scroll-up/scroll-up';

interface Props {
  data: {
    allWpPost: any;
  };
}

export default function AktuellSpeziell(props: Props): JSX.Element {
  const posts = props.data?.allWpPost?.edges.map((edge) => edge.node);
  // console.log('aktuell speziell posts', posts);

  return (
    <BasicStructure>
      <TitleMetaTags title="Aktuell Speziell" />
      <Header>
        <Menubar styleClass="main-menu" />
        <BurgerMenu />
      </Header>
      <Sidebar>
        <ScrollUp />
      </Sidebar>
      <ContentContainer>
        <section className="aktuell-speziell-page">
          {posts &&
            posts.map((post) => {
              return (
                <article key={post.id} className="layout--margin-bottom-60">
                  <h2
                    className="heading-square aktuell-speziell-title"
                    dangerouslySetInnerHTML={{ __html: post.title }}
                  />
                  {post.blocks.length > 0 &&
                    post.blocks.map((block, index) => {
                      const WpBlock = getWpBlock(block.__typename);
                      if (!WpBlock) {
                        return null;
                      }
                      const blockKey = `${post.id}-${block.id ?? index}`;
                      return (
                        <div className="content-block" key={blockKey}>
                          <WpBlock block={block} />
                        </div>
                      );
                    })}
                </article>
              );
            })}
        </section>
      </ContentContainer>
      <Footer />
    </BasicStructure>
  );
}

export const pageQuery = graphql`
  query blogPosts {
    allWpPost(
      limit: 1000
      filter: {
        categories: {
          nodes: { elemMatch: { slug: { eq: "aktuellspeziell" } } }
        }
      }
      sort: { fields: date, order: DESC }
    ) {
      edges {
        node {
          id
          title
          blocks {
            __typename
            dynamicContent
            isDynamic
            #            originalContent
            #            saveContent
            ...WPColumns
            ...WpCoreImageBlockFragment
            ...WpCoreGalleryBlockFragment
            ...WpCoreParagraphBlockFragment
            #            ... on WpCoreEmbedYoutubeBlock {
            #              __typename
            #              # originalContent
            #              attributes {
            #                ... on WpCoreEmbedYoutubeBlockAttributes {
            #                  caption
            #                  url
            #                  #                  className
            #                  providerNameSlug
            #                  type
            #                }
            #              }
            #            }
            innerBlocks {
              __typename
              # saveContent
              isDynamic
              dynamicContent
              ...WpCoreImageBlockForGalleryFragment
              ...WpCoreGalleryBlockFragment
            }
          }
          # content
          uri
        }
      }
    }
  }
`;
